html,
body,
#root {
	height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: Manrope, sans-serif;
}

#tsparticles {
	position: fixed;
	height: 100%;
	width: 100%;
	margin: 0;
	padding: 0;
	left: 0;
	top: 0;
	z-index: -1;
	background-color: #000812;
}

/* DivTable.com */
.divTable {
	display: table;
	width: 100%;
	background-color: transparentr;
}
.divTableRow {
	display: table-row;
}
.divTableHeading {
	background-color: transparent;
	display: table-header-group;
}
.divTableCell,
.divTableHead {
	border: 1px solid #999999;
	display: table-cell;
	padding: 3px 10px;
}
.divTableHeading {
	background-color: transparent;
	display: table-header-group;
	font-weight: bold;
}
.divTableFoot {
	background-color: transparent;
	display: table-footer-group;
	font-weight: bold;
}
.divTableBody {
	display: table-row-group;
}

.hidden {
	color: #080812;
	font-size: 1.25em;
	font-family: Manrope, sans-serif;
	text-align: center;
	font-weight: 900;
	width: 100%;
}
.app {
	display: flex;
	flex-grow: 1;
	height: 100%;
	width: 100%;
	flex-direction: column;
	text-align: center;
	align-items: center;
	justify-content: center;
}

.app-content {
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	align-items: center;
}

.descope-container {
	border-radius: 15px;
	box-shadow: rgba(70, 255, 205, 0.06) 0px 2px 4px 0px inset;
	max-width: 600px;
	min-width: 280px;
}

.welcome-title {
	margin-bottom: 0px;
	font-family: Manrope, sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 48px;
	line-height: 128%;
	letter-spacing: 0.6px;
	color: #46ffcd;
}

.example-title {
	margin-bottom: 0px;
	font-family: Manrope, sans-serif;
	font-style: normal;
	font-size: 20px;
	letter-spacing: 0.6px;
}

.example {
	border: 2px solid;
	min-width: 350px;
	border-radius: 100px;
	border-color: #46ffcd;
	padding: 16px 32px;
	background-color: transparent;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	word-break: break-all;
}

.copy-icon {
	margin-left: 6px;
	height: 100%;
}

.text-body {
	font-size: 20px;
	display: inline-block;
}

h1 {
	margin: 0px;
	font-weight: 800;
	font-size: 32px;
	line-height: 128%;
}

p {
	font-family: 'Manrope';
	font-weight: 400;
	font-style: normal;
	line-height: 160%;
	text-align: center;
	display: flex;
	letter-spacing: 0.6px;
	color: rgba(255, 255, 255, 0.87);
	margin-top: 8px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
	.app-content {
		width: 90%;
	}

	.descope-container {
		margin-left: 16px;
		margin-right: 16px;
	}

	.example {
		min-width: fit-content;
	}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
	.app-content {
		width: 80%;
	}
	.example {
		min-width: fit-content;
	}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
	.app-content {
		width: 55%;
	}
	.example {
		min-width: 350px;
	}
}
